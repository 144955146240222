import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { db } from "../firebaseConfig";
import moment from "moment";


const CursoFormModal = ({ show, handleClose, curso, getData, setCurso }) => {

    const formRef = useRef(null)

    const [alert, setAlert] = useState({show: false, message: '', variant: ''})

    const [loading, setLoading] = useState(false)

    const [estado, setEstado] = useState()
    const [instituicao, setInstituicao] = useState()
    const [dataInscricao, setDataInscricao] = useState()
    const [dataProva, setDataProva] = useState()
    const [modalidade, setModalidade] = useState()
    const [obs, setObs] = useState()
    const [link, setLink] = useState('')

    const clearForm = () => {
        setCurso()
    }

    const addConcurso = () => {
        if(!formRef.current.checkValidity()) {
            setAlert({show: true, message: 'Formulário Inválido', variant: 'danger'})
            setTimeout(() => {
                setAlert({show: false, message: '', variant: ''})
            },  2000)
        }else{
            setLoading(true)
            addDoc(collection(db, 'concursos'),{
                estado: estado || '',
                instituicao: instituicao || '',
                dataInscricao: dataInscricao || '',
                dataProva: dataProva || '',
                modalidade: modalidade || '',
                obs: obs || '',
                link: link || '',
                criadoEm: moment().format("YYYY-MM-DD hh:mm:ss a")
            }).then(response => {
                setAlert({show: true, message: 'Concurso adicionado com sucesso', variant: 'success'})
                getData()
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setAlert({show: true, message: 'Erro ao adicionar concurso', variant: 'danger'})
                setLoading(false)
            })
            setTimeout(() => {
                setAlert({show: false, message: '', variant: ''})
                clearForm()
                handleClose()
            },  3000)
        }
    }

    const editCurso = () => {
        setLoading(true)
        updateDoc(doc(db, 'concursos', curso?.id),{
            estado: estado || '',
            instituicao: instituicao || '',
            dataInscricao: dataInscricao || '',
            dataProva: dataProva || '',
            modalidade: modalidade || '',
            obs: obs || '',
            link: link || ''
        }).then(response => {
            setAlert({show: true, message: 'Concurso editado com sucesso', variant: 'success'})
            getData()
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setAlert({show: true, message: 'Erro ao editar concurso', variant: 'danger'})
            setLoading(false)
        })
        setTimeout(() => {
            setAlert({show: false, message: '', variant: ''})
            clearForm()
            handleClose()
        },  3000)
    }

    useEffect(() => {
        if(curso){
            setEstado(curso?.estado)
            setInstituicao(curso?.instituicao)
            setDataInscricao(curso?.dataInscricao)
            setDataProva(curso?.dataProva)
            setModalidade(curso?.modalidade)
            setObs(curso?.obs)
            setLink(curso?.link)
        }
    }, [curso])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
            <Modal.Title>{curso ? 'Editar Curso' : 'Adicionar Curso'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {alert?.show && <Alert variant={alert?.variant}>{alert?.message}</Alert>}
                <Form ref={formRef}>
                    <Form.Group style={{marginBottom: '10px'}}>
                        <Form.Label>Estado</Form.Label>
                        <Form.Control type="text" value={estado} required onChange={(e) => setEstado(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '10px'}}>
                        <Form.Label>Instituição</Form.Label>
                        <Form.Control type="text" value={instituicao} required onChange={(e) => setInstituicao(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '10px'}}>
                        <Form.Label>Inscrição até</Form.Label>
                        <Form.Control type="text" value={dataInscricao} required onChange={(e) => setDataInscricao(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '10px'}}>
                        <Form.Label>Data Prova</Form.Label>
                        <Form.Control type="text" value={dataProva} required onChange={(e) => setDataProva(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '10px'}}>
                        <Form.Label>Modalidade</Form.Label>
                        <Form.Control type="text" value={modalidade} required onChange={(e) => setModalidade(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '10px'}}>
                        <Form.Label>Obs</Form.Label>
                        <Form.Control as='textarea' value={obs} required onChange={(e) => setObs(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '10px'}}>
                        <Form.Label>Link</Form.Label>
                        <Form.Control type="text" value={link} required onChange={(e) => setLink(e.target.value)}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={curso ? editCurso : addConcurso} disabled={alert.show || loading}>Salvar</Button>
            </Modal.Footer>
      </Modal>
    )
}

export default CursoFormModal;