import { Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import Header from './components/Header';
import Footer from './components/Footer';
import Concursos from './pages/Concursos';
import Login from './pages/Login';
import SemVagas from './pages/SemVagas';

function App() {
  return (
    <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
      <Header/>
      <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route path='/concursos' element={<Concursos/>}/>
        <Route path='/semvagas' element={<SemVagas/>}/>
        <Route path='/admin' element={<Login/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
