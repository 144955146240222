import { Button, Form, Table } from "react-bootstrap";
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import  { db } from '../firebaseConfig';
import { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useAuth } from "../context/AuthContext";
import CursoFormModal from "../components/CursoFormModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";


const Concursos = () => {

    const { currentUser } = useAuth()

    const navigate = useNavigate()

    const [showFormModal, setShowFormModal] = useState(false)

    const [data, setData] = useState([])
    const [itemToEdit, setItemToEdit] = useState()

    const [searchQuery, setSearchQuery] = useState('')
    const [selectedSearch, setSelectedSearch] = useState({display: 'Estado', value: 'estado'})
    const searchOptions = [
        {display: 'Estado', value: 'estado'},
        {display: 'Instituição', value: 'instituicao'}
    ]

    const normalizeString = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    const filteredData = data.filter(item => {
        const normalizedEstado = normalizeString(item.estado);
        const normalizedInstituicao = normalizeString(item.instituicao);
        const normalizedSearchQuery = normalizeString(searchQuery);
    
        return normalizedEstado.includes(normalizedSearchQuery) || normalizedInstituicao.includes(normalizedSearchQuery);
    })

    const getData = () => {
        getDocs(collection(db, 'concursos')).then(response => {
            setData(
                response.docs
                    .map(doc => ({id: doc.id, ...doc.data()}))
                    .sort((a, b) => {
                        const momentA = moment(a.criadoEm, 'YYYY-MM-DD hh:mm:ss a');
                        const momentB = moment(b.criadoEm, 'YYYY-MM-DD hh:mm:ss a');
                      
                        return momentB.utc().valueOf() - momentA.utc().valueOf();
                    })
            )
        }).catch(err => {
            console.log(err)
        })
    }

    const deleteData = (id) => {
        deleteDoc(doc(db, 'concursos', id)).then(response => {
            getData()
        }).catch(err => console.log(err))
    }

    const goToCurso = (link) => {
        if(link){
            window.open(link, '_blank')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return(
        <div style={{padding: '30px 50px', flex: 1}}>
            <h1 style={{fontWeight: 'bold'}}>Concursos</h1>
            <div style={{display: 'flex', justifyContent: 'end'}}>
                {currentUser && <Button onClick={() => setShowFormModal(true)}>Adicionar Curso</Button>}
            </div>
            <div style={{display: 'flex'}}>
                {/*<InputGroup>
                    <DropdownButton onChange={e => console.log(e)} title={selectedSearch?.display} style={{backgroundColor: 'red'}}>
                        {searchOptions.map(option => (
                            <Dropdown.Item style={{backgroundColor: 'red'}} onClick={() => setSelectedSearch(option)}>{option?.display}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </InputGroup>*/}
                <Form.Control type='text' placeholder="Pesquise por estado ou instituição..." onChange={e => setSearchQuery(e.target.value)}/>
            </div>
            <Table style={{marginTop: '30px'}} responsive hover>
                <thead>
                    <tr>
                        <th>Estado</th>
                        <th>Instituição</th>
                        <th>Inscrição até</th>
                        <th>Data Prova</th>
                        <th>Modalidade</th>
                        <th>Observações</th>
                        {currentUser && <th>Ação</th>}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map(item => (
                        <tr key={item.id} style={{cursor: item.link && 'pointer'}}>
                            <td onClick={() => goToCurso(item?.link)}>{item.estado}</td>
                            <td onClick={() => goToCurso(item?.link)} style={{fontWeight: 'bold', fontStyle: 'italic'}}>{item.instituicao}</td>
                            <td onClick={() => goToCurso(item?.link)}>{item.dataInscricao}</td>
                            <td onClick={() => goToCurso(item?.link)}>{item.dataProva}</td>
                            <td onClick={() => goToCurso(item?.link)}>{item.modalidade}</td>
                            <td onClick={() => goToCurso(item?.link)}>{item.obs}</td>
                            {currentUser && <td>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <AiFillEdit
                                        size={22}
                                        onClick={() => {
                                            setItemToEdit(item)
                                            setShowFormModal(true)
                                        }}
                                        color="blue"
                                        style={{marginRight: '10px', cursor: 'pointer'}}
                                    />
                                    <AiFillDelete
                                        size={22}
                                        onClick={() => deleteData(item.id)}
                                        color="red"
                                        style={{cursor: 'pointer'}}
                                    />
                                </div>
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {showFormModal && <CursoFormModal show={showFormModal} handleClose={() => setShowFormModal(false)} curso={itemToEdit} getData={getData} setCurso={setItemToEdit}/>}
        </div>
    )

}

export default Concursos;