

const SemVagas = () => {

    return(
        <div style={{padding: '30px 50px', flex: 1, display: 'flex', justifyContent: 'center'}}>
            <img src="/sem-vagas.jpg"/>
        </div>
    )

}

export default SemVagas;