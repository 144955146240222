import { useState } from "react"
import { Alert, Button, Form } from "react-bootstrap"
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from "react-router-dom";


const Login = () => {

    const navigate = useNavigate()

    const auth = getAuth()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const [alert, setAlert] = useState({show: false, message: '', variant: ''})

    const handleLogin = () => {
        signInWithEmailAndPassword(auth, email, password).then(userCredential => {
            navigate('/concursos')
        }).catch(err => {
            console.log(err)
            setAlert({show: true, message: 'Erro ao fazer o login', variant: 'danger'})
        })
        setTimeout(() => {
            setAlert({show: false, message: '', variant: ''})
        },  5000)
    }

    return(
        <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {alert?.show && (
                <Alert variant={alert?.variant}>{alert?.message}</Alert>
            )}
            <Form style={{minWidth: '300px'}}>
                <Form.Group style={{marginBottom: '10px'}}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="email" required onChange={(e) => setEmail(e.target.value)}/>
                </Form.Group>
                <Form.Group style={{marginBottom: '10px'}}>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control type="password" placeholder="senha" required onChange={(e) => setPassword(e.target.value)}/>
                </Form.Group>
                <Button variant="primary" onClick={handleLogin}>Entrar</Button>
            </Form>
        </div>
    )
}

export default Login;