import { useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { useAuth } from "../context/AuthContext";


const Header = () => {

    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const handleToggle = () => setOpen(!open)

    const { currentUser, logout } = useAuth()

    const menus = [
        {title: 'Home', href: '/'},
        //{title: 'Saiba mais', href: '/aboutUs'},
        {title: 'Concursos para CD', href: '/concursos'},
        {title: 'Assine o Curso', href: process.env.REACT_APP_LINK_CURSO},
        {title: 'Comunidade MP', href: process.env.REACT_APP_LINK_COMUNIDADE}
    ]

    const handleMenuClick = (menu) => {
        if(menu.title === 'Assine o Curso' || menu?.title === 'Comunidade MP')
            window.open(menu?.href, '_blank')
        else navigate(menu?.href)
    }

    return(
        <Navbar style={{padding: 0}} expand='lg' collapseOnSelect>
            <Container
                fluid
                style={{padding: '10px 20px', background: '#130937', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
                <Navbar.Brand
                    onClick={() => {navigate('/')}}
                >
                    <img
                        src="logo.png"
                        width='250'
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle}>
                    <RxHamburgerMenu color="white" size='30'/>    
                </Navbar.Toggle>
                <Navbar.Collapse in={open} id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="ml-auto" style={{fontSize: '18px', alignItems: 'center'}}>
                        {menus.map(menu => (
                            <Nav.Link onClick={() => handleMenuClick(menu)} style={{padding: '0 20px', color: 'white', textDecoration: 'none', display: 'flex', justifyContent: 'end'}}>{menu?.title}</Nav.Link>
                        ))}
                        {currentUser && <Button onClick={logout}>Sair</Button>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;