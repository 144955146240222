import { FaMobileScreenButton, FaInstagram, FaFacebookF } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { TfiYoutube } from "react-icons/tfi";


const Footer = () => {

    return(
        <div
            style={{
                background: '#110937',
                color: 'white',
                padding: '70px 100px',
                backgroundImage: 'url(footer.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '100%'
            }}
        >
            <h3 style={{fontSize: '40px', fontWeight: 600}}>
                Alguma dúvida?<br />
                Chama a gente no WhatsApp
            </h3>
            <div style={{margin: '0 auto 40px'}}/>
            <div>
                <div style={{display: 'flex', marginBottom: '10px', flexWrap: 'wrap'}}>
                    <h4 style={{display: 'flex', marginRight: '30px', alignItems: 'center', cursor: 'pointer'}} onClick={() => window.open('https://api.whatsapp.com/send?phone=5541992296500', '_blank')}>
                        <FaMobileScreenButton style={{marginRight: '10px'}}/>
                        Whatsapp
                    </h4>
                    <h4 style={{display: 'flex', marginRight: '30px', alignItems: 'center', cursor: 'pointer'}} onClick={() => window.open('mailto:meupreparo@gmail.com', '_blank')}>
                        <IoMail style={{marginRight: '10px'}}/>
                        meupreparo@gmail.com
                    </h4>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <h4 style={{display: 'flex', marginRight: '30px', alignItems: 'center', cursor: 'pointer'}} onClick={() => window.open('https://www.instagram.com/meupreparo/', '_blank')}>
                        <FaInstagram style={{marginRight: '10px'}}/>
                        @meupreparo
                    </h4>
                    <h4 style={{display: 'flex', marginRight: '30px', alignItems: 'center', cursor: 'pointer'}} onClick={() => window.open('https://www.facebook.com/meupreparo.com.br/', '_blank')}>
                        <FaFacebookF style={{marginRight: '10px'}}/>
                        @meupreparo.com.br
                    </h4>
                    <h4 style={{display: 'flex', marginRight: '30px', alignItems: 'center', cursor: 'pointer'}} onClick={() => window.open('https://www.youtube.com/channel/UCzXFFOYxmmqFpJbOyyl5HSg', '_blank')}>
                        <TfiYoutube style={{marginRight: '10px'}}/>
                        Meu Preparo
                    </h4>
                </div>
            </div>                 
            <h6 style={{fontSize: '17px', fontWeight: 'bold', marginTop: '20px'}}> Responsável legal: CRO PR 6514</h6>                
        </div>
    )
}

export default Footer;