import { useEffect, useRef } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { FaCheck, FaRegTimesCircle, FaPlus } from "react-icons/fa";
import CustomToggle from "../components/CustomToggle";


const Landing = () => {

    const iframeRef = useRef(null)

    const goToPay = () => {
        window.open(process.env.REACT_APP_LINK_CURSO, '_blank')
    }

    return(
        <iframe
            ref={iframeRef}
            src="https://marketing.pnyta.com/meu-preparo/"
            height={"5390px"}
        />
//         <div style={{flex: 1}}>
//             <div style={{padding: '5px', background: '#EAFF00', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
//                 <span style={{fontSize: '18px', fontWeight: 600}}>ASSINE E COMEÇE AGORA A SUA MELHOR CLASSIFICAÇÃO</span>
//             </div>
//             <div style={{backgroundImage: 'url(/background-1.jpg)', backgroundSize: 'cover', position: 'relative', paddingBottom: '20px'}}>
//                 <div style={{background: 'transparent', backgroundImage: 'linear-gradient(180deg, #223242 0%, #455668 100%)', opacity: '0.8', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}}/>
//                 <div style={{width: '100%', padding: '20px', textAlign: 'center', position: 'relative'}}>
//                     <img src="/landing-logo.png" style={{width: '300px', height: 'auto', maxWidth: '100%'}}/>
//                 </div>
//                 <div style={{width: '100%', position: 'relative', maxWidth: '1140px', marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
//                     <Row>
//                         <Col lg={6} md={12}>
//                         <div style={{width: '100%', padding: '0 25px', flexWrap: 'wrap', display: 'flex', alignContent: 'flex-start'}}>
//                             <span style={{textTransform: 'uppercase', fontSize: '42px', fontWeight: 600, letterSpacing: '-0.8px', color: 'white', lineHeight: '42px', marginBottom: '15px'}}>Curso Preparatório para Concursos para CD</span>
//                             <div style={{padding: '15px 0 15px 0', backgroundColor: 'transparent'}}>
//                                     <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', justifyContent: 'flex-start', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                             <FaCheck style={{color: '#06CE07', margin: '0 10px'}} size='20'/>
//                                         </div>
//                                         <span style={{fontSize: '15px'}}>
//                                             Preparando CD’s para concursos <b>há mais de 10 anos.</b>
//                                         </span>
//                                     </div>
//                                     <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', justifyContent: 'flex-start', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                             <FaCheck style={{color: '#06CE07', margin: '0 10px', fontSize: '20px'}} size='20'/>
//                                         </div>
//                                         <span style={{fontSize: '15px'}}>
//                                             Disciplinas <b>odontológicas, não-odontológicas + plataforma de questões.</b>
//                                         </span>
//                                     </div>
//                                     <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', justifyContent: 'flex-start', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                             <FaCheck style={{color: '#06CE07', margin: '0 10px', fontSize: '20px'}} size='20'/>
//                                         </div>
//                                         <span style={{fontSize: '15px'}}>
//                                             <b>Maior aprovação de CD’s</b> no Brasil de <b>2017 a 2023!</b>
//                                         </span>
//                                     </div>
//                                 </div>
//                             <Button variant="dark" style={{borderRadius: '25px', background: '#00FF01', fontSize: '17px', fontWeight: 'bold', color: 'black', display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '15px 30px', marginBottom: '15px'}}>Conheça o curso que vai te ajudar a ser aprovado</Button>
//                             <span style={{textTransform: 'uppercase', fontSize: '18px', fontWeight: 600, letterSpacing: '-0.8px', color: 'white', lineHeight: '22px', marginBottom: '15px'}}>ESTAMOS PRONTOS PARA PREPARAR VOCÊ PARA CONQUISTAR A SUA VAGA</span>
//                             <span style={{color: '#A7A7A7', fontSize: '11px', fontWeight: '600', lineHeight: '12px', letterSpacing: '0.5px'}}>Fonte: Instituto Educa Brasil. Síntese de Pesquisas Anuais. In: FSP, Caderno Educação. “Preparação para Resultados”, 22/12/2023.</span>
//                         </div>
//                         </Col>
//                         <Col lg={6} md={12}>
//                         <div style={{width: '100%', padding: '50px 25px', display: 'flex', alignContent: 'flex-start', flexWrap: 'wrap', justifyContent: 'center'}}>
//                             <iframe style={{borderRadius: '20px', maxWidth: '560px'}} width="100%" height="315" src="https://www.youtube.com/embed/zMLZ3yECQjQ?si=Kg0hkpnYh-Oosm7w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
//                         </div>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>
//             <div style={{background: 'linear-gradient(180deg, #223242 0%, #223242 100%)', display: 'flex', justifyContent: 'center', width: '100%', padding: '30px 20px', flexDirection: 'column', alignItems: 'center'}}>
//                 <span style={{color: 'white', fontSize: '38px', fontWeight: 600, lineHeight: '42px', letterSpacing: '-0.8px', maxWidth: '1140px', marginBottom: '30px'}}>ESTAMOS PRONTOS PARA PREPARAR VOCÊ PARA O SUCESSO</span>
//                 <div style={{width: '100%', position: 'relative', maxWidth: '1140px', marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
//                     <Row>
//                         <Col lg={6} md={12}>
//                             <div style={{width: '100%'}}>
//                                 <div style={{border: '1px solid #7A7A7A', borderRadius: '25px', margin: '15px', padding: '25px'}}>
//                                     <h4 style={{fontSize: '28px', fontWeight: 400, lineHeight: '30px', letterSpacing: '-0.8px', color: 'white', marginBottom: '20px'}}>Principais fontes de frustração nos estudos para os concursos:</h4>
//                                     <div style={{padding: '15px 0 15px 0', backgroundColor: 'transparent', backgroundImage: 'linear-gradient(93deg, #380000 0%, #223242 100%)'}}>
//                                         <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                                 <FaRegTimesCircle style={{color: 'red', margin: '0 10px'}} size='20'/>
//                                             </div>
//                                             <span style={{fontSize: '16px', fontWeight: 400, lineHeight: '19px'}}>Falta de tempo e incerteza sobre o que priorizar nos estudos.</span>
//                                         </div>
//                                         <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                                 <FaRegTimesCircle style={{color: 'red', margin: '0 10px'}} size='20'/>
//                                             </div>
//                                             <span style={{fontSize: '16px', fontWeight: 400, lineHeight: '19px'}}>Estudar por materiais inadequados às provas de concurso e que prejudicam o seu progresso.</span>
//                                         </div>
//                                         <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                                 <FaRegTimesCircle style={{color: 'red', margin: '0 10px'}} size='20'/>
//                                             </div>
//                                             <span style={{fontSize: '16px', fontWeight: 400, lineHeight: '19px'}}>Técnica de estudo inadequada para concursos.</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                         <Col lg={6} md={12}>
//                             <div style={{width: '100%'}}>
//                                 <div style={{border: '2px solid #04E405', borderRadius: '20px', padding: '35px', backgroundColor: '#041A38'}}>
//                                     <h4 style={{fontSize: '28px', fontWeight: 400, lineHeight: '30px', letterSpacing: '-0.8px', color: 'white', marginBottom: '20px'}}>No curso preparatório para CD’s do MeuPreparo, você encontra:</h4>
//                                     <div style={{padding: '15px 0 15px 0', backgroundColor: 'transparent'}}>
//                                         <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', justifyContent: 'flex-start', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                                 <FaCheck style={{color: '#06CE07', margin: '0 10px', fontSize: '20px'}} size='20'/>
//                                             </div>
//                                             <span style={{fontSize: '16px', fontWeight: 400, lineHeight: '19px'}}>Falta de tempo e incerteza sobre o que priorizar nos estudos.</span>
//                                         </div>
//                                         <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', justifyContent: 'flex-start', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                                 <FaCheck style={{color: '#06CE07', margin: '0 10px', fontSize: '20px'}} size='20'/>
//                                             </div>
//                                             <span style={{fontSize: '16px', fontWeight: 400, lineHeight: '19px'}}>Estudar por materiais inadequados às provas de concurso e que prejudicam o seu progresso.</span>
//                                         </div>
//                                         <div style={{paddingBottom: 'calc(25px/2)', display: 'flex', justifyContent: 'flex-start', textAlign: 'left', color: 'white', alignItems: 'center'}}>
//                                             <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                                                 <FaCheck style={{color: '#06CE07', margin: '0 10px', fontSize: '20px'}} size='20'/>
//                                             </div>
//                                             <span style={{fontSize: '16px', fontWeight: 400, lineHeight: '19px'}}>Técnica de estudo inadequada para concursos.</span>
//                                         </div>
//                                     </div>
//                                     <Button onClick={goToPay} variant="dark" style={{borderRadius: '25px', background: '#00FF01', fontSize: '17px', fontWeight: 'bold', color: 'black', display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '15px 30px'}}>
//                                         O preparo ideal para você está aqui!
//                                     </Button>
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>
//             <div style={{backgroundImage: 'url(/background-2.jpg)', backgroundSize: 'cover', position: 'relative', padding: '20px 0'}}>
//                 <div style={{width: '100%', position: 'relative', maxWidth: '1140px', marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
//                     <Row>
//                         <Col lg={6} md={12}>
//                             <div style={{width: '100%', padding: '0 25px', flexWrap: 'wrap', display: 'flex', alignContent: 'flex-start'}}/>
//                         </Col>
//                         <Col lg={6} md={12}>
//                         <div style={{width: '100%', padding: '50px 25px', display: 'flex', alignContent: 'flex-start', flexWrap: 'wrap'}}>
//                     <span style={{fontSize: '28px', fontWeight: 600, letterSpacing: '-0.8px', color: '#223242', lineHeight: '33px', marginBottom: '15px'}}>O MeuPreparo obteve o 1º lugar nas Pesquisas Anuais do Instituto Educa Brasil de 2017 a 2023) nas categorias:
// </span>
//                         <div style={{alignContent: 'flex-start', display: 'flex', flexWrap: 'wrap', justifyContent: 'start', fontFamily: 'Helvetica'}}>
//                             <ul style={{color: 'white', listStyleType: 'none', paddingLeft: 0}}>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Uma base com análise completa e atualizada de todas as provas em todo o Brasil.
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Material completo que otimiza seu tempo e recursos, impulsionando sua evolução.
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Técnicas de estudos, questões e simulados para reforçar o aprendizado das aulas.
//                                     </span>
//                                 </li>
//                             </ul>
//                         </div>
//                         <span style={{marginBottom: '20px', fontSize: '16px', fontWeight: 600, lineHeight: '19px', letterSpacing: '0.5px', color: '#535353'}}>Tenha <strong>tudo o que você precisa</strong> para se preparar <strong>em um só lugar</strong> e <strong>economize</strong> o seu <strong>tempo</strong> e <strong>dinheiro</strong> estudando apenas o que de fato pode cair na sua prova.</span>
//                         <Button onClick={goToPay} variant="dark" style={{borderRadius: '25px', background: '#00FF01', fontSize: '17px', fontWeight: 'bold', color: 'black', display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '15px 30px', marginBottom: '15px', border: 'none'}}>Conquiste a sua melhor classificação! >></Button>
//                     </div>
//                         </Col>
//                     </Row>

//                 </div>
//             </div>
//             <div style={{backgroundImage: 'url(/background-3.jpg)', backgroundSize: 'cover', position: 'relative', padding: '30px 20px'}}>
//             <div style={{width: '100%', position: 'relative', maxWidth: '1140px', marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
//                     <Row style={{width: '100%'}}>
//                         <Col lg={6} md={12}>
//                             <div style={{width: '100%', padding: '32px', flexWrap: 'wrap', display: 'flex', alignContent: 'flex-start', borderStyle: 'solid', borderRadius: '20px', borderWidth: '3px 1px 1px 3px', borderColor: '#89acc2', backgroundColor: '#eaeef3'}}>
//                                 <span style={{fontSize: '40px', fontWeight: 600, letterSpacing: '-0.8px', color: '#223242', marginBottom: '20px', lineHeight: 1, width: '100%'}}>COMECE AGORA E SAIA NA FRENTE DOS SEUS CONCORRENTES!</span>
//                                 <div style={{alignContent: 'flex-start', display: 'flex', flexWrap: 'wrap', justifyContent: 'start', fontFamily: 'Helvetica'}}>
//                             <ul style={{color: 'white', listStyleType: 'none', paddingLeft: 0}}>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Método descomplicado
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Conteúdo focado em concursos para CD
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Adequado para qualquer concurso para CD
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Excelente relação custo-benefício
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Disciplinas odontológicas e não-odontológicas
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Aulas online, acesse as aulas quando quiser
//                                     </span>
//                                 </li>
//                                 <li style={{margin: '12px 0', fontSize: '15px', color: '#535353'}}>
//                                     <span>
//                                         <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
//                                             <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
//                                         </svg>
//                                     </span>
//                                     <span>
//                                         Destaque para os conteúdos e questões mais cobrados
//                                     </span>
//                                 </li>
//                             </ul>
//                                 </div>
//                                 <Button onClick={goToPay} variant="dark" style={{borderRadius: '25px', background: '#00FF01', fontSize: '17px', fontWeight: 'bold', color: 'black', display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '15px 30px', marginBottom: '15px', border: 'none'}}>Descomplique a sua preparação agora! >></Button>    
//                             </div>
//                         </Col>
//                         <Col lg={6} md={12}>
//                         <div style={{width: '100%', padding: '50px 25px', display: 'flex', alignContent: 'flex-start', flexWrap: 'wrap'}}/>
//                         </Col>
//                     </Row>                    
//                 </div>
//             </div>
//             <div style={{background: 'linear-gradient(180deg, #223242 0%, #223242 100%)', display: 'flex', justifyContent: 'center', width: '100%', padding: '30px 20px', flexDirection: 'column', alignItems: 'center'}}>
//                 <span style={{color: 'white', fontSize: '38px', fontWeight: 600, lineHeight: '42px', letterSpacing: '-0.8px', maxWidth: '1140px', marginBottom: '70px'}}>
//                     Chegou a hora de conquistar o <span style={{color: '#04E405'}}>MELHOR PREPARO</span> para o concurso de Cirurgiões-Dentistas e alcançar a tão desejada <span style={{color: '#04E405'}}>APROVAÇÃO!!</span>
//                 </span>
//                 <div style={{width: '100%', position: 'relative', maxWidth: '1140px', marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
//                     <Row>
//                         <Col lg={6} md={12}>
//                             <div style={{width: '100%', backgroundColor: 'transparent', backgroundImage: 'linear-gradient(89deg,#0F5664 0%,#0A7FA5 100%)', borderRadius: '20px', borderStyle: 'solid', borderWidth: '3px 1px 1px 3px', borderColor: '#2ba0b4', padding: '25px'}}>
//                                 <div style={{marginBottom: '30px', width: '100%'}}>
//                                     <div style={{margin: '-90px 0 0', display: 'flex', justifyContent: 'center'}}>
//                                         <img src="/garantia-7.png"
//                                             style={{height: 'auto', maxWidth: '100%', width: '175px', verticalAlign: 'middle'}}
//                                         />
//                                     </div>
//                                     <h4 style={{color: 'white', fontSize: '25px', fontWeight: 900, lineHeight: '30px', textAlign: 'center', marginBottom: '20px'}}>GARANTIA 7 DIAS</h4>
//                                     <span style={{textAlign: 'center', color: 'white', fontSize: '17px', fontWeight: 400, lineHeight: '24px'}}>Invista com segurança! Caso você não se adapte ou precise desistir, garantimos o reembolso integral do seu investimento até 7 dias após a compra do curso.</span>
//                                     <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
//                                         <img src="/sitelock.jpg" style={{width: '150px', maxWidth: '100%', height: 'auto', verticalAlign: 'middle'}}/>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                         <Col lg={6} md={12}>
//                             <div class="py-5 py-sm-5 py-md-0 py-lg-0" style={{width: '100%'}}>
//                                 <div style={{padding: '10px', borderRadius: '25px', border: '3px solid #00c6ff', boxShadow: '0 0 19px 4px rgba(0,191.80434782608694,255,.42)', margin: '0 20px', backgroundColor: '#010d1b'}}>
//                                     <div style={{margin: '-40px 0 -10px', display: 'flex', justifyContent: 'center', padding: '10px 10px 2px', backgroundColor: '#158673', borderRadius: '10px', marginBottom: '20px'}}>
//                                         <span style={{color: 'white', textAlign: 'center', fontSize: '28px', lineHeight: '35px', letterSpacing: '0.5px', textShadow: '0 0 10px #0d473e', fontWeight: 'bold'}}>APROVEITE AGORA!</span>
//                                     </div>
//                                     <div style={{alignContent: 'flex-start', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', fontFamily: 'Helvetica'}}>
//                                         <ul style={{color: 'white', listStyleType: 'none', paddingLeft: 0}}>
//                                             <li style={{margin: '5px 0', fontSize: '15px', color: 'white', display: 'flex', alignItems: 'center'}}>
//                                                 <FaPlus style={{color: '#ffff99', marginRight: '5px'}}/>
//                                                 <span>
//                                                     Matérias odontológicas e não-odontológicas
//                                                 </span>
//                                             </li>
//                                             <li style={{margin: '5px 0', fontSize: '15px', color: 'white', display: 'flex', alignItems: 'center'}}>
//                                                 <FaPlus style={{color: '#ffff99', marginRight: '5px'}}/>
//                                                 <span>
//                                                     Mais de 200 aulas disponíveis no curso
//                                                 </span>
//                                             </li>
//                                             <li style={{margin: '5px 0', fontSize: '15px', color: 'white', display: 'flex', alignItems: 'center'}}>
//                                                 <FaPlus style={{color: '#ffff99', marginRight: '5px'}}/>
//                                                 <span>
//                                                     750 mil questões sobre todos os assuntos
//                                                 </span>
//                                             </li>
//                                             <li style={{margin: '5px 0', fontSize: '15px', color: 'white', display: 'flex', alignItems: 'center'}}>
//                                                 <FaPlus style={{color: '#ffff99', marginRight: '5px'}}/>
//                                                 <span>
//                                                     Acesso integral por 18 meses
//                                                 </span>
//                                             </li>
//                                             <li style={{margin: '5px 0', fontSize: '15px', color: 'white', display: 'flex', alignItems: 'center'}}>
//                                                 <FaPlus style={{color: '#ffff99', marginRight: '5px'}}/>
//                                                 <span>
//                                                     Certificado de conclusão de curso
//                                                 </span>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '15px'}}>
//                                         <span style={{color: 'white', fontSize: '20px', fontWeight: 600, lineHeight: '28px', margin: '-5px 0 -40px'}}>por <strong>APENAS</strong></span>
//                                         <span style={{color: '#00ff23', fontSize: '120px', fontWeight: 800, letterSpacing: '-2.5px'}}><span style={{fontSize: '60px'}}>R$</span>56<span style={{fontSize: '30px'}}>,77</span></span>
//                                         <span style={{color: 'white', fontSize: '16px', fontWeight: 400, lineHeight: '23px'}}>em até 12x com juros</span>
//                                         <span style={{color: 'white', fontSize: '19px', fontWeight: 400, lineHeight: '23px'}}>(Ou <strong>R$ 568,80</strong> à vista)</span>
//                                     </div>
//                                     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '15px'}}>
//                                         <Button variant="dark" style={{borderRadius: '25px', background: '#00FF01', fontSize: '17px', fontWeight: 'bold', color: 'black', display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '15px 30px'}}>
//                                             Inicie esta jornada >>
//                                         </Button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>
//             <div style={{backgroundImage: 'url(/background-4.jpg)', position: 'relative', padding: '30px 20px'}}>
//                 <div style={{width: '100%', position: 'relative', maxWidth: '1140px', marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
//                     <Row style={{width: '100%'}}>
//                         <Col lg={6} md={12}>
//                             <div style={{width: '100%', padding: '50px 25px', display: 'flex', alignContent: 'flex-start', flexWrap: 'wrap'}}/>
//                         </Col>
//                         <Col lg={6} md={12}>
//                             <div style={{width: '100%', display: 'flex', alignContent: 'flex-start', flexWrap: 'wrap', flexDirection: 'column'}}>
//                                 <span style={{color: '#223242', fontSize: '40px', fontWeight: 600, letterSpacing: '-0.8px'}}>PRINCIPAIS DÚVIDAS</span>
//                                 <div style={{padding: '10px', width: '100%'}}>
//                                         <Accordion defaultActiveKey={'0'} style={{marginBottom:'20px'}}>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='1' color='black'>
//                                                         O curso afirma que é o que mais aprova/classifica CD’s. De onde os números são obtidos?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="1">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                         O número de vagas conquistadas é anualmente analisado e divulgado por instituições independentes e publicadas em veículos de comunicação de abrangência nacional. Os números que indicam que desde 2017 somos o curso que mais aprova e melhor classifica CD’s em concursos e seleções no Brasil são obtidos dessas instituições após uma nova checagem interna.
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='2' color='black'>
//                                                         O curso é dirigido para concursos específicos?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="2">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                         O curso é dedicado às especificidades das provas de concursos para cirurgiões-dentistas, para qualquer prova, de qualquer banca.
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='3' color='black'>
//                                                         O curso tem vídeos ou textos?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="3">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                         As aulas possuem textos focados no conteúdo das provas para CD, vídeo complementar, dicas e acesso à Plataforma com mais de 750.000 questões, odontológicas e não-odontológicas.
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='4' color='black'>
//                                                         Posso fazer o curso pelo celular?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="4">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                         Sim, o curso pode ser acessado em qualquer dispositivo conectado à internet e em qualquer sistema operacional (Windows, Mac OSX, Android, IOS, Linux e outros).
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='5' color='black'>
//                                                         Quanto tempo preciso para fazer o curso?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="5">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                         O tempo necessário para a preparação completa dependerá da disponibilidade de tempo para estudo e da maior ou menor amplitude do conteúdo programático do edital da sua prova. O tempo médio vai de 30 a 45 dias caso sejam feitas todas as aulas.
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='6' color='black'>
//                                                         Qual o tempo de acesso ao assinar o curso?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="6">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                     O acesso é integral e ilimitado por 18 meses.
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='7' color='black'>
//                                                         E se eu precisar desistir do curso?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="7">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                         O MeuPreparo respeita rigorosamente a legislação que trata da desistência. A partir da data da compra você tem 07 (sete) dias para desistir com a garantia de devolução integral do valor investido.
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                             <Card style={{background: 'transparent', border: 'none'}}>
//                                                 <Card.Header style={{background: 'transparent', cursor: 'pointer', border: 'none'}}>
//                                                     <CustomToggle eventKey='8' color='black'>
//                                                         Posso compartilhar o acesso ao curso com outra(s) pessoa(s)?
//                                                     </CustomToggle>
//                                                 </Card.Header>
//                                                 <Accordion.Collapse eventKey="8">
//                                                     <Card.Body style={{padding: '15px', color: 'black', fontSize: '15px', fontWeight: 400}}>
//                                                         Não, o acesso ao curso é pessoal e individual. O compartilhamento é monitorado e proibido por lei.
//                                                     </Card.Body>
//                                                 </Accordion.Collapse>   
//                                             </Card>
//                                         </Accordion>
//                                     </div>
//                             </div>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>
//     </div>
    )
}   

export default Landing;

/*
<iframe
            ref={iframeRef}
            src="https://landingmeupreparo.wpcomstaging.com/landing/"
            height={"5369px"}
        />
*/

/*<div style={{flex: 1}}>
            <div style={{padding: '5px', background: '#EAFF00', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span style={{fontSize: '18px', fontWeight: 600}}>ASSINE E COMEÇE AGORA A SUA MELHOR CLASSIFICAÇÃO</span>
            </div>
            <div style={{backgroundImage: 'url(/background-1.jpg)', backgroundSize: 'cover', backgroundAttachment: 'fixed', position: 'relative'}}>
                <div style={{background: 'transparent', backgroundImage: 'linear-gradient(180deg, #223242 0%, #455668 100%)', opacity: '0.8', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}}/>
                <div style={{width: '100%', padding: '20px', textAlign: 'center', position: 'relative'}}>
                    <img src="/landing-logo.png" style={{width: '300px', height: 'auto', maxWidth: '100%'}}/>
                </div>
                <div style={{width: '100%', position: 'relative', maxWidth: '1140px', marginLeft: 'auto', marginRight: 'auto', display: 'flex'}}>
                    <div style={{width: '45%', padding: '0 25px', flexWrap: 'wrap', display: 'flex', alignContent: 'flex-start'}}>
                        <span style={{textTransform: 'uppercase', fontSize: '42px', fontWeight: 600, letterSpacing: '-0.8px', color: 'white', lineHeight: '42px', marginBottom: '15px'}}>Curso Preparatório para Concursos para CD</span>
                        <div style={{alignContent: 'flex-start', display: 'flex', flexWrap: 'wrap', justifyContent: 'start'}}>
                            <ul style={{color: 'white', listStyleType: 'none', paddingLeft: 0}}>
                                <li style={{margin: '12px 0', fontSize: '15px'}}>
                                    <span>
                                        <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
                                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Preparando CD’s para concursos <b>há mais de 10 anos.</b>
                                    </span>
                                </li>
                                <li style={{margin: '12px 0', fontSize: '15px'}}>
                                    <span>
                                        <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
                                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        Disciplinas <b>odontológicas, não-odontológicas + plataforma de questões.</b>
                                    </span>
                                </li>
                                <li style={{margin: '12px 0', fontSize: '15px'}}>
                                    <span>
                                        <svg aria-hidden="true" class="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" style={{fill: '#06CE07', width: '20px', marginRight: '5px'}}>
                                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        <b>Maior aprovação de CD’s</b> no Brasil de <b>2017 a 2023!</b>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <Button variant="dark" style={{borderRadius: '25px', background: '#00FF01', fontSize: '17px', fontWeight: 'bold', color: 'black', display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '15px 30px', marginBottom: '15px'}}>Conheça o curso que vai te ajudar a ser aprovado</Button>
                        <span style={{textTransform: 'uppercase', fontSize: '18px', fontWeight: 600, letterSpacing: '-0.8px', color: 'white', lineHeight: '22px', marginBottom: '15px'}}>ESTAMOS PRONTOS PARA PREPARAR VOCÊ PARA CONQUISTAR A SUA VAGA</span>
                        <span style={{color: '#A7A7A7', fontSize: '11px', fontWeight: '600', lineHeight: '12px', letterSpacing: '0.5px'}}>Fonte: Instituto Educa Brasil. Síntese de Pesquisas Anuais. In: FSP, Caderno Educação. “Preparação para Resultados”, 22/12/2023.</span>
                    </div>
                    <div style={{width: '55%', padding: '50px 25px', display: 'flex', alignContent: 'flex-start', flexWrap: 'wrap'}}>
                        <iframe style={{display: 'flex', height: '100%', width: '100%', border: 'none', borderRadius: '20px'}} src="https://www.youtube.com/watch?v=zMLZ3yECQjQ&ab_channel=MeuPreparo"/>
                    </div>
                </div>
            </div>
    </div>*/