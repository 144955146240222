import { useAccordionButton } from "react-bootstrap";
import { RiArrowRightSFill } from "react-icons/ri";


const CustomToggle = ({ children, eventKey, color }) => {

    const decoratedOnClick = useAccordionButton(eventKey)

    return (
        <div style={{width: '100%', color: color, fontSize: '16px', fontWeight: 600, letterSpacing: '0.5px', display: 'flex', alignItems: 'center'}} onClick={decoratedOnClick}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <RiArrowRightSFill style={{color: '#07b908'}} size='22'/>
            </div>
            {children}
        </div>
    )
}

export default CustomToggle;